import React from "react";
// External
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SplashText from "../components/SplashText";
import { fontFamily, spacing, breakpoints } from "../../theme";
import useMediaQuery from "../hooks/use-media-query";
import Seo from "../components/SEO";

const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  display: block;
  // padding-bottom: 60px;
  font-size: 26px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  padding: ${spacing(10)} 0 ${spacing(5)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing(3)} 0 ${spacing(5)};
  }
`;

const AboutMeContainer = styled.div`
  width: calc(100% - ${spacing(8)});
  padding: 0 ${spacing(4)} 60px;
  background: #fff;

  @media only screen and (min-width: 500px) {
    width: calc(100% - ${spacing(16)});
    padding: 0 ${spacing(8)} 60px;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 80%;
    margin: 0 auto 130px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    width: 65%;
    margin: 0 auto 130px;
  }

  @media only screen and (min-width: 1350px) {
    width: 55%;
    margin: 0 auto 130px;
  }

  p {
    font-size: 18px;

    letter-spacing: -0.003em;
    line-height: 32px;
    margin-top: 2em;
    margin-bottom: -0.46em;
    color: rgba(41, 41, 41, 1);

    @media only screen and (min-width: ${breakpoints.tablet}) {
      font-size: 20px;
    }
  }

  h1 {
    // letter-spacing: 0;
    // line-height: 36px;
    // margin-top: 1.95em;
    // font-size: 48px;
    // margin-bottom: -0.28em;
    // font-family: ${fontFamily.bold};
  }

  h3 {
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 1.95em;
    font-size: 30px;
    margin-bottom: -0.28em;
    font-family: ${fontFamily.bold};
  }

  ol,
  ul {
    padding: 0;
    list-style: none;
    list-style-image: none;
  }

  li {
    margin-top: 0.86em;
    letter-spacing: -0.003em;
    line-height: 32px;
    font-size: 20px;
    padding-left: 0px;
    margin-left: 30px;
    list-style-type: decimal;
    margin-bottom: -0.46em;
  }

  p {
  }
`;
const AboutPage = () => {
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.tablet} )`);

  return (
    <Layout>
      <Seo
        title="About"
        description="Brief information about Parmeet Singh Asija"
      />
      <AboutMeContainer>
        <ImageWrapper>
          {isMobile ? (
            <StaticImage
              src="../images/me.jpeg"
              alt="Parmeet Singh"
              width={208}
              height={208}
              imgStyle={{
                borderRadius: "50%",
              }}
              placeholder="none"
            />
          ) : (
            <StaticImage
              src="../images/me.jpeg"
              alt="Parmeet Singh"
              width={318}
              height={318}
              imgStyle={{
                borderRadius: "50%",
              }}
              placeholder="none"
            />
          )}
        </ImageWrapper>
        <Heading>
          <SplashText variant="peach">About Me</SplashText>
        </Heading>
        <p>
          Hey 👋🏻 I'm Parmeet Singh, I'm a Frontend Developer from India. I like
          to work on hard problems where technology can have a long-term
          meaningful impact.
          <br />
          <br />
          During the past 3+ years of working with website development, I've
          gained insight into a large area of technical domains ranging from
          both front and back end development as well as system architecture and
          UX. I fully understand the Agile development methodology and I have
          worked in teams following the same.
          <br />
          <br />
          I've been heavily invested in the JavaScript ecosystem and have
          in-depth knowledge and experience with React, GraphQL, Typescript and
          more.
          <br />
          <br />
          Additionally, I'm sharing my knowledge on various Social Media
          platforms and Blogs.
        </p>
      </AboutMeContainer>
    </Layout>
  );
};

export default AboutPage;
